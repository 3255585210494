import Icon from "./Icon";

const WebsitePreloader = () => {
  return (
    <div className="fixed inset-0 m-0 flex items-center justify-center bg-gray-50/50">
      <Icon />
    </div>
  );
}

export default WebsitePreloader;
