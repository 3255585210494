import { Navbar } from "flowbite-react";
import Heading from "../../headings/Heading";
import BackButton from "./BackButton";
import LogoutButton from "./LogoutButton";
import LanguageSelector from "../../theme/LanguageSelector";
import { DarkThemeToggle } from "flowbite-react";

const Header = ({ disableArrow, heading, backPath }) => {
  const getHeading = (isMobile) => {
    const className = isMobile ? 'mt-4 text-center px-2 block sm:hidden' : 'hidden sm:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2';
    return (
      <Heading
        type="h1"
        size="h5"
        className={className}
      >
        {heading}
      </Heading>
    );
  }

  return (
    <header>
      <Navbar
        fluid
        className="bg-white dark:bg-gray-800 relative"
      >
        {disableArrow ? <div></div> : <BackButton path={backPath} />}
        {getHeading()}
        <div className="flex items-center gap-2 lg:order-2 lg:gap-3">
          <LanguageSelector />
          <DarkThemeToggle />
          <LogoutButton />
        </div>
      </Navbar>
      {getHeading(true)}
    </header>
  )
}

export default Header;
