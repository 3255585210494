import { useTranslation } from "react-i18next";
import LoginForm from "../../components/auth/LoginForm";
import Heading from "../../components/auth/Heading";

const Login = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <Heading text={t('auth:loginHeading')} />
      <LoginForm />
    </>
  )
}

export default Login;
