import { Link } from "react-router-dom";
import { Button } from "flowbite-react";

const BackButton = ({ path }) => {
  return (
    <Button
      color="gray"
      as={Link}
      to={path}
      className="border-0 text-cyan-700 hover:bg-gray-50 dark:text-gray-100 dark:hover:bg-gray-700"
    >
      <svg
        className="size-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 12h14M5 12l4-4m-4 4 4 4"
        />
      </svg>
    </Button>
  )
}

export default BackButton;
