import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "flowbite-react";
import { useFormContext } from "../../contexts/FormContext";
import FormInput from "./FormInput";

const AddressModal = ({ show, onClose, properties, focus }) => {
  const { t, i18n } = useTranslation('meet');
  const { formData, setFormData } = useFormContext();
  const [inputs, setInputs] = useState([]);
  const [tempData, setTempData] = useState({});

  useEffect(() => {
    if (show) {
      const initialTempData = Object.keys(properties).reduce((acc, key) => {
        acc[key] = formData[key];

        return acc;
      }, {});

      setTempData(initialTempData);
    }
  }, [show, formData, properties]);

  useEffect(() => {
    const _inputs = Object.keys(properties).map(key => {
      return {
        type: 'text',
        name: key,
        id: key,
        required: key !== 'flat',
        label: properties[key],
        value: tempData[key],
        disabled: ['city', 'countryName'].includes(key),
      }
    });

    setInputs(_inputs);
  }, [formData, i18n.language, properties, tempData]);

  const handleChange = e => {
    const { name, value } = e.target;

    setTempData({
      ...tempData,
      [name]: value,
    });
  }

  const handleSave = () => {
    setFormData({
      ...formData,
      ...tempData,
    });

    onClose();
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
    >
      <Modal.Header>{t('meet:changeAddress')}</Modal.Header>
      <Modal.Body>
        <div className="grid gap-4 md:gap-6">
          {inputs.map((input, key) => (
            <FormInput
              input={input}
              key={key}
              onChange={handleChange}
              focus={focus}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSave}>Сохранить</Button>
        <Button
          color="gray"
          onClick={onClose}
        >
          Отмена
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddressModal;
