import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import FormInput from "../forms/FormInput";
import { resetPassword } from "../../api/auth";

const ResetPasswordForm = ({ token }) => {
  const { t } = useTranslation(['auth', 'common']);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    token,
  });

  const inputs = [
    {
      type: 'password',
      name: 'password',
      id: 'password',
      label: t('auth:password'),
      placeholder: '••••••••••••••',
      required: true,
    },
    {
      type: 'password',
      name: 'confirmPassword',
      id: 'confirmPassword',
      label: t('auth:confirmPassword'),
      placeholder: '••••••••••••••',
      required: true,
    },
  ];

  const handleChange = e => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsProcessing(true);
    setErrors(false);

    try {
      await resetPassword(formData);

      toast.success(t('auth:passwordResetSuccess'));
      
      navigate('/login');
    } catch (error) {
      console.error(error);

      error.response?.data?.errors ? setErrors(error.response.data.errors) : toast.error(t('common:unexpectedError'));
    } finally {
      setIsProcessing(false);
    }
  }

  return (
    <form
      className="space-y-4 md:space-y-6"
      onSubmit={handleSubmit}
    >
      {inputs.map((input, key) => (
        <FormInput
          input={input}
          key={key}
          onChange={handleChange}
          error={errors?.[input.name]}
        />
      ))}
      <Button
        type="submit"
        className="w-full"
        isProcessing={isProcessing}
        disabled={isProcessing}
      >
        {t('auth:resetPassword')}
      </Button>
    </form>
  );
}

export default ResetPasswordForm;
