import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <section>
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <div className="mx-auto max-w-screen-sm text-center">
          <img
            alt="404"
            src="/images/routes/6342461.svg"
            className="mx-auto mb-4"
          />
          <h1 className="mb-4 text-2xl font-extrabold">Страница не найдена</h1>
          <ul className="flex items-center justify-center space-x-4 text-gray-500 dark:text-gray-400">
            <li>
              <Link to="/" className="underline hover:text-gray-900 dark:hover:text-white">Вернуться на главную</Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
