import { Routes, Route } from "react-router-dom";
import WithAuthorization from "./WithAuthorization";
import WithoutAuthorization from "./WithoutAuthorization";
import WithCompany from "./WithCompany";
import WithoutCompany from "./WithoutCompany";
import ModalLayout from "../components/layouts/Modal";
import DashboardLayout from "../components/layouts/Dashboard";
import AuthLayout from "../components/layouts/Auth";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Logout from "../pages/auth/Logout";
import Meet from "../pages/meet/Meet";
import MeetCompany from "../pages/meet/MeetCompany";
import Dashboard from "../pages/Dashboard";
import NotFound from "./NotFound";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<WithoutAuthorization />}>
        <Route element={<AuthLayout />}>
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/sign-up"
            element={<SignUp />}
          />
          <Route
            path="/forgot-password"
            element={<ForgotPassword />}
          />
        </Route>
      </Route>
      <Route
        path="/logout"
        element={<Logout />}
      />
      <Route element={<WithAuthorization />}>
        <Route element={<WithoutCompany />}>
          <Route element={<ModalLayout />}>
            <Route
              path="/meet"
              element={<Meet />}
            />
            <Route
              path="/meet/company"
              element={<MeetCompany />}
            />
          </Route>
        </Route>
        <Route element={<WithCompany />}>
          <Route element={<DashboardLayout />}>
            <Route
              path="/"
              element={<Dashboard />}
            />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes;
