import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { AuthContextProvider, useAuth } from "./contexts/AuthContext";
import { FormProvider } from "./contexts/FormContext";
import AppRoutes from "./routes/AppRoutes";
import WebsitePreloader from "./components/preloaders/WebsitePreloader";
import { useThemeMode } from "flowbite-react";

function App() {
  const theme = useThemeMode();

  if (window.location.hostname !== process.env.REACT_APP_AUTH_HOSTNAME || process.env.NODE_ENV !== 'production') {
    return (
      // eslint-disable-next-line tailwindcss/no-custom-classname
      <div className="font-inter bg-gray-50 dark:bg-gray-900 min-h-screen">
        <BrowserRouter
          future={{
            v7_startTransition: true,
          }}
        >
          <AuthContextProvider>
            <FormProvider>
              <AppContent />
            </FormProvider>
          </AuthContextProvider>
        </BrowserRouter>
        <Toaster
          toastOptions={{
            style: {
              background: theme.computedMode === 'dark' ? '#333333' : '#ffffff',
              color: theme.computedMode === 'dark' ? '#ffffff' : '#333333',
              borderRadius: '8px',
              padding: '10px',
              boxShadow: theme.computedMode === 'dark' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(0, 0, 0, 0.1)',
            },
          }}
        />
      </div>
    );
  }

  return <>subdomain logic here</>;
}

function AppContent() {
  const { loading } = useAuth();

  return loading ? <WebsitePreloader /> : <AppRoutes />;
}

export default App;
