import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";
import toast from "react-hot-toast";
import { FaYandex } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { FaVk } from "react-icons/fa6";
import Cookies from "js-cookie";
import { Button } from "flowbite-react";
import { signInOAuth } from "../../api/auth";
import { generateCodeVerifier, generateCodeChallenge } from "../../lib/pkce";
import { useAuth } from "../../contexts/AuthContext";
import OverlayPreloader from "../preloaders/OverlayPreloader";
import { getLoginRedirectUrl } from "../../utils/auth";
import styles from "./OAuth.module.css";

const iconClassNames = 'mr-2 h-5 w-5';

const OAuth = () => {
  const { t } = useTranslation(['auth', 'common']);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const deviceId = queryParams.get('device_id');
  const navigate = useNavigate();
  const { setAuthUser } = useAuth();

  useEffect(() => {
    if (state && state === 'vkLogin') {
      setVkCode(code);
      setVkDeviceId(deviceId);
    } else {
      setYandexCode(code);
    }
  }, [code, state, deviceId]);

  const googleHandler = useGoogleLogin({
    onSuccess: codeResponse => setGoogleUser(codeResponse),
    onError: error => {
      console.error(error);
      toast.error(t('auth:googleOAuthError'));
    }
  });

  const yandexHandler = () => {
    try {
      const url = new URL('https://oauth.yandex.ru/authorize');

      url.searchParams.append('response_type', 'code');
      url.searchParams.append('client_id', process.env.REACT_APP_YANDEX_OAUTH_CLIENT_ID);

      window.location.href = url.toString();
    } catch (error) {
      console.error(error);
      toast.error(t('auth:yandexOAuthError'));
    }
  }

  const vkHandler = async () => {
    try {
      const verifier = generateCodeVerifier();
      const challenge = await generateCodeChallenge(verifier);
      const url = new URL('https://id.vk.com/authorize');
      
      Cookies.set('vkCodeVerifier', verifier);
      url.searchParams.append('client_id', process.env.REACT_APP_VK_CLIENT_ID);
      url.searchParams.append('redirect_uri', `${process.env.REACT_APP_APP_URL}/login`);
      url.searchParams.append('code_challenge', challenge);
      url.searchParams.append('code_challenge_method', 's256');
      url.searchParams.append('state', 'vkLogin');
      url.searchParams.append('response_type', 'code');
      url.searchParams.append('scope', 'vkid.personal_info,email');

      window.location.href = url.toString();
    } catch (error) {
      console.error('Ошибка авторизации VK:', error);
      toast.error(t('auth:vkOAuthError'));
    }
  }

  const options = [
    {
      icon: <FcGoogle className={iconClassNames} />,
      name: 'Google',
      handler: googleHandler,
    },
    {
      icon: <FaYandex className={iconClassNames} color="#ff0000" />,
      name: t('auth:yandex'),
      handler: yandexHandler,
    },
    {
      icon: <FaVk className={iconClassNames} color="#0077ff" />,
      name: 'VK',
      handler: vkHandler,
    },
  ];

  const [googleUser, setGoogleUser] = useState(null);
  const [yandexCode, setYandexCode] = useState(null);
  const [vkCode, setVkCode] = useState(null);
  const [vkDeviceId, setVkDeviceId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      let response = null;
      let data = null;

      if (googleUser) {
        data = {
          service: 'google',
          accessToken: googleUser.access_token,
        }
      }

      if (yandexCode) {
        data = {
          service: 'yandex',
          accessToken: yandexCode,
        }
      }

      if (vkCode && deviceId) {
        data = {
          service: 'vk',
          accessToken: vkCode,
          deviceId: vkDeviceId,
          codeVerifier: Cookies.get('vkCodeVerifier'),
          state,
        }
      }

      if (data) {
        setIsLoading(true);

        try {
          response = await signInOAuth(data);

          setIsLoading(false);

          const { user, token } = response;

          if (!user || !token) {
            toast.error(t('common:unexpectedError'));
          }

          setAuthUser(user, token);
          navigate(getLoginRedirectUrl(user));
        } catch (error) {
          setIsLoading(false);

          toast.error(error.message);
          console.error(error);
        }
      }
    }

    fetchUserProfile();
  }, [googleUser, yandexCode, vkCode, deviceId, navigate, setAuthUser, state, vkDeviceId, t]);

  return (
    <div className="relative">
      <div className="space-y-4 md:space-y-6">
        <div className="flex items-center">
          <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-700"></div>
          <div className="whitespace-nowrap px-5 text-center text-gray-500 dark:text-gray-400">
            {t('auth:orLoginVia')}
          </div>
          <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-700"></div>
        </div>
        <div className="flex items-center justify-center gap-2">
          {options.map((option, key) => (
            <Button
              color="light"
              size="sm"
              key={key}
              onClick={option.handler}
              className={styles.button}
            >
              {option.icon}
              <span className="hidden sm:inline">{option.name}</span>
            </Button>
          ))}
        </div>
      </div>
      {!!isLoading && <OverlayPreloader />}
    </div>
  );
}

export default OAuth;
