import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import clsx from "clsx";
import { Label, TextInput, Select } from "flowbite-react";
import { RadioGroup } from "@headlessui/react";
import { getCountries } from "../../utils/phoneInput";
import styles from "./TextInput.module.css";
import "react-international-phone/style.css";
import "@geoapify/geocoder-autocomplete/styles/minimal.css";
import AddressInput from "./AddressInput";
import AvatarUploader from "./AvatarUploader";

const FormInput = ({ input, onChange, error, focus, onBlur }) => {
  const { i18n } = useTranslation('auth');
  const { id, name, placeholder, required, type, label, customClassName, value, tip, options, hidden, addon, disabled } = input;
  const inputRef = useRef(null);

  useEffect(() => {
    if (focus === name && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus, name]);

  if (hidden) {
    return null;
  }

  const color = error ? 'failure' : 'gray';

  const getTextInput = () => {
    return (
      <TextInput
        id={id}
        ref={inputRef}
        type={type}
        name={name}
        placeholder={placeholder}
        required={!!required}
        onChange={onChange}
        onBlur={onBlur}
        color={color}
        value={value || ''}
        addon={addon}
        disabled={disabled}
        theme={{
          base: 'flex flex-row-reverse',
          addon: 'inline-flex items-center rounded-r-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400',
          field: {
            input: {
              withAddon: {
                on: 'rounded-l-lg',
              }
            }
          }
        }}
      />
    );
  }

  const getInput = () => {
    switch (type) {
      case 'tag':
        if (!options || options.length === 0) {
          return <></>;
        }

        return (
          <RadioGroup
            value={value}
            onChange={value => onChange({
              target: {
                name,
                value,
              }
            })}
          >
            <div className="flex flex-wrap gap-3">
              {Object.keys(options).map(key => (
                <RadioGroup.Option
                  key={key}
                  value={key}
                  className={({ active, checked }) => clsx(
                    'rounded-md py-2 px-4 text-sm cursor-pointer focus:outline-none flex-shrink-0',
                    {
                      'ring-2 ring-cyan-600 ring-offset-2': active,
                      'bg-cyan-600 text-white dark:bg-cyan-600 dark:text-white': checked,
                      'ring-1 dark:ring-0 ring-gray-300 bg-white text-gray-900 hover:bg-gray-50 dark:bg-gray-700 dark:text-white': !checked,
                      'dark:ring-gray-600': active && !checked
                    })
                  }
                >
                  <RadioGroup.Label as="span">{options[key]}</RadioGroup.Label>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        );

      case 'select':
        if (!options || options.length === 0) {
          return <></>;
        }

        return (
          <Select
            id={id}
            required={!!required}
            onChange={onChange}
            name={name}
            value={value}
            color={color}
          >
            {!!placeholder && <option disabled>{placeholder}</option>}
            {Object.keys(options).map(key => (
              <option
                value={key}
                key={key}
              >
                {options[key]}
              </option>
            ))}
          </Select>
        );

      case 'tel':
        return (
          <PhoneInput
            defaultCountry="ru"
            id={id}
            className={clsx(styles.phoneInputContainer, styles[color])}
            countries={getCountries({
              lang: i18n.language.split('-')[0],
            })}
            name={name}
            required={!!required}
            onChange={phone => onChange({
              target: {
                name,
                value: phone,
              }
            })}
          />
        );

      case 'file':
        return (
          <AvatarUploader
            name={name}
            value={value}
            onChange={onChange}
            required={!!required}
            color={color}
          />
        );

      case 'address':
        return (
          <AddressInput
            value={value}
            onChange={onChange}
            color={color}
          />
        );

      default:
        return getTextInput();
    }
  }

  return (
    <div className={customClassName}>
      {!!label && (
        <Label
          htmlFor={id}
          value={label}
          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
        />
      )}
      <div className="flex">
        <div className="relative w-full">
          {getInput()}
          {!!tip && <Label className="mt-1 text-xs font-normal text-gray-500 dark:text-gray-300">{tip}</Label>}
          {!!error?.message && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{error.message}</p>}
        </div>
      </div>
    </div>
  );
}

export default FormInput;