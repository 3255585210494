const Logo = () => {
  return (
    <>
      <img
        src="/logo192.png"
        className="mr-3 size-6 sm:size-9"
        alt="qbook"
      />
      <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">qbook</span>
    </>
  );
}

export default Logo;
