import axios from "./axiosClient";

export const getUser = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/users/my')
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}
