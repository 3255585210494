import { useState } from "react";
import { Avatar, FileInput } from "flowbite-react";

const AvatarUploader = ({ name, value, onChange, required, color }) => {
  const [imageSrc, setImageSrc] = useState(value);

  const handleFileChange = e => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => setImageSrc(reader.result);

      reader.readAsDataURL(file);
    } else {
      setImageSrc(null);
    }

    onChange(e);
  }

  return (
    <div className="items-center w-full sm:flex">
      <Avatar
        alt={name}
        img={imageSrc}
        rounded
        size="lg"
        className="[&_img]:max-w-none mb-4 sm:mr-4 sm:mb-0"
      />
      <div className="w-full">
        <FileInput
          aria-describedby="file_input_help"
          id={name}
          name={name}
          required={required}
          color={color}
          onChange={handleFileChange}
          className="w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder:text-gray-400"
        />
      </div>
    </div>
  );
}

export default AvatarUploader;
