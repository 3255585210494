import axios from "./axiosClient";

export const createCompany = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/companies', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}

export const validateSlug = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/companies/slug/validate', data)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}

export const getCompany = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/companies/my')
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}
