import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Button } from "flowbite-react";
import CardForm from "../forms/CardForm";
import { useAuth } from "../../contexts/AuthContext";
import { useFormContext } from "../../contexts/FormContext";
import { createCompany, validateSlug } from "../../api/company";
import languages from "../../utils/languages.json";
import timezones from "../../utils/timezones.json";
import currencies from "../../utils/currencies.json";

const CompanyForm = () => {
  const [errors, setErrors] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const { t, i18n } = useTranslation(['common', 'meet', 'currencies']);
  const { formData, setFormData } = useFormContext();
  const [languageList, setLanguageList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const navigate = useNavigate();
  const { refreshUserData } = useAuth();

  useEffect(() => {
    const formattedLanguages = Object.fromEntries(Object.entries(languages).map(([key, value]) => [key, value.name]));
    const _currencyList = Object.fromEntries(currencies.map(currency => [currency, `${t(`currencies:${currency}`)} - ${currency}`]).sort((a, b) => a[1].localeCompare(b[1])));

    setLanguageList(formattedLanguages);
    setCurrencyList(_currencyList);
  }, [i18n.language, t]);

  useEffect(() => {
    if (!formData.language) {
      setFormData(prev => ({
        ...prev,
        language: i18n.language.split('-')[0] || 'ru',
      }));
    }

    if (!formData.timezone) {
      setFormData(prev => ({
        ...prev,
        timezone: moment.tz.guess() || 'Europe/Moscow',
      }));
    }

    if (!formData.currency) {
      setFormData(prev => ({
        ...prev,
        currency: 'RUB',
      }));
    }
  }, [formData, i18n.language, setFormData]);

  const logoInputs = [
    {
      type: 'file',
      name: 'logo',
      id: 'logo',
      tip: t('meet:logoTip'),
      required: true,
      value: formData.logo,
    },
  ];

  const nameInputs = [
    {
      type: 'text',
      name: 'companyName',
      id: 'companyName',
      tip: t('meet:companyNameTip'),
      required: true,
      placeholder: t('common:enterName'),
      value: formData.companyName,
    },
  ];

  const domainInputs = [
    {
      type: 'text',
      name: 'slug',
      id: 'slug',
      tip: t('meet:domainNameTip'),
      placeholder: t('common:enterName'),
      addon: '.qbook.me',
      required: true,
      value: formData.slug,
    },
  ];

  const addressInputs = [
    {
      type: 'address',
      name: 'address',
      id: 'address',
      placeholder: t('meet:searchAddress'),
      required: true,
      value: formData.formattedAddress,
    },
  ];

  const settingsInputs = [
    {
      type: 'select',
      name: 'language',
      id: 'language',
      required: true,
      options: languageList,
      value: formData.language || i18n.language.split('-')[0] || 'ru',
      label: t('meet:languageLabel'),
    },
    {
      type: 'select',
      name: 'timezone',
      id: 'timezone',
      required: true,
      options: timezones,
      value: formData.timezone || moment.tz.guess() || 'Europe/Moscow',
      label: t('meet:timezoneLabel'),
    },
    {
      type: 'select',
      name: 'currency',
      id: 'currency',
      required: true,
      options: currencyList,
      value: formData.currency || 'RUB',
      label: t('meet:currencyLabel'),
    }
  ];

  const mergeAddress = errors => {
    const addressFields = ['lat', 'lon', 'addressLine1', 'placeId'];
    const addressMessages = addressFields.filter(field => errors[[field]]);

    if (addressMessages.length) {
      errors.address = {
        name: 'address',
        message: t('meet:addressInvalid'),
      }
    }

    return errors;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsProcessing(true);

    const data = new FormData();

    for (const key in formData) {
      if (key === 'logo') {
        const fileInput = document.querySelector('input[name="logo"]');

        if (fileInput && fileInput.isDefaultNamespace.length) {
          data.append('file', fileInput.files[0]);
        }
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      await createCompany(data);
      await refreshUserData();

      navigate('/');
    } catch (err) {
      console.error(err);

      if (err.response?.data?.errors) {
        const meetErrorKeys = ['branches', 'position', 'type', 'sphere', 'employees', 'selfEmployed'];
        
        if (meetErrorKeys.some(key => key in err.response.data.errors)) {
          navigate('/meet');
        } else {
          const _errors = mergeAddress(err.response.data.errors);

          setErrors(_errors);
        }
      } else {
        toast.error(t('common:unexpectedError'))
      }
    } finally {
      setIsProcessing(false);
    }
  }

  const sections = [
    {
      heading: t('meet:logoLabel'),
      inputs: logoInputs
    },
    {
      heading: t('meet:companyLabel'),
      inputs: nameInputs,
    },
    {
      heading: t('meet:domainNameLabel'),
      inputs: domainInputs,
    },
    {
      heading: t('meet:addressLabel'),
      inputs: addressInputs,
    },
    {
      heading: t('common:settings'),
      inputs: settingsInputs,
    }
  ];

  const handleChange = async (e) => {
    let newFormData = {};

    if (e.targets) {
      const obj = e.targets.reduce((acc, { name, value }) => {
        acc[name] = value;

        return acc;
      }, {});

      newFormData = obj;
    } else {
      const { name, value } = e.target;

      newFormData = {
        [name]: value,
      }
    }

    setFormData({
      ...formData,
      ...newFormData,
    });
  }

  const handleBlur = async (e) => {
    const { name, value } = e.target;

    if (name === 'slug') {
      try {
        await validateSlug({
          slug: value,
        });

        const { slug, ...rest } = errors;

        setErrors({
          ...rest
        });
      } catch (err) {
        console.error(err);

        err.response?.data?.errors ? setErrors(err.response.data.errors) : toast.error(t('common:unexpectedError'));
      }
    }
  }

  return (
    <form
      className="grid gap-4 md:gap-6"
      onSubmit={handleSubmit}
    >
      <CardForm
        onChange={handleChange}
        onBlur={handleBlur}
        sections={sections}
        errors={errors}
      />
      <Button
        type="submit"
        className="w-full sticky bottom-4"
        isProcessing={isProcessing}
        disabled={isProcessing}
      >
        {t('common:save')}
      </Button>
    </form>
  );
}

export default CompanyForm;
