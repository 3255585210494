import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import ForgotPasswordForm from "../../components/auth/ForgotPasswordForm";
import ResetPasswordForm from "../../components/auth/ResetPasswordForm";
import Heading from "../../components/auth/Heading";
import { validateResetToken } from "../../api/auth";
import WebsitePreloader from "../../components/preloaders/WebsitePreloader";

const ForgotPassword = () => {
  const { t } = useTranslation(['auth', 'common']);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async (token) => {
      try {
        await validateResetToken({
          token,
        });

        setLoading(false);
      } catch (err) {
        console.error(err);

        if (err.status === 403) {
          toast.error(t('auth:tokenExpired'));

          navigate('/forgot-password');
        } else {
          toast.error(t('common:unexpectedError'));
        }

        setLoading(false);
      }
    }

    token ? validateToken(token) : setLoading(false);
  }, [token, navigate, t]);

  if (loading) {
    return <WebsitePreloader />;
  }

  return (
    <>
      <Heading text={t('auth:passwordReset')} />
      {token ? <ResetPasswordForm token={token} /> : <ForgotPasswordForm />}
    </>
  )
}

export default ForgotPassword;
