import { useTranslation } from "react-i18next";
import SignUpForm from "../../components/auth/SignUpForm";
import Heading from "../../components/auth/Heading";

const SignUp = () => {
  const { t } = useTranslation('auth');
  
  return (
    <>
      <Heading text={t('auth:signUpHeading')} />
      <SignUpForm />
    </>
  )
}

export default SignUp;
