import { useTranslation } from "react-i18next";
import { Label } from "flowbite-react";

const AddressEditable = ({ label, onAdd, name }) => {
  const { t } = useTranslation('common');

  return (
    <div>
      <Label
        value={label}
        className="block"
      />
      <button
        className="mt-1 text-sm font-normal text-cyan-700 hover:underline"
        onClick={e => onAdd({
          e,
          name,
        })}
      >
        +
        &nbsp;
        {t('common:add')}
      </button>
    </div>
  );
}

export default AddressEditable;
