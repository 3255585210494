import { useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Button } from "flowbite-react";
import FormInput from "../forms/FormInput";
import { forgotPassword } from "../../api/auth";

const ForgotPasswordForm = () => {
  const { t } = useTranslation(['auth', 'common']);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState(false);
  
  const [formData, setFormData] = useState({
    email: '',
  });

  const inputs = [
    {
      type: 'text',
      name: 'email',
      id: 'email',
      label: t('auth:emailLabel'),
      placeholder: 'youremail@business.com',
      required: true,
      value: formData.email,
    },
  ];

  const handleChange = e => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsProcessing(true);
    setErrors(false);

    try {
      await forgotPassword(formData);

      toast.success(t('auth:resetEmailSent'));
    } catch (error) {
      console.error(error);

      error.response?.data?.errors ? setErrors(error.response.data.errors) : toast.error(t('common:unexpectedError'));
    } finally {
      setIsProcessing(false);
    }
  }

  return (
    <form
      className="space-y-4 md:space-y-6"
      onSubmit={handleSubmit}
    >
      {inputs.map((input, key) => (
        <FormInput
          input={input}
          key={key}
          onChange={handleChange}
          error={errors?.[input.name]}
        />
      ))}
      <Button
        type="submit"
        className="w-full"
        isProcessing={isProcessing}
        disabled={isProcessing}
      >
        {t('auth:resetPassword')}
      </Button>
    </form>
  );
}

export default ForgotPasswordForm;
