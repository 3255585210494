import { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./modal/Header";

const ModalLayout = () => {
  const [disableArrow, setDisableArrow] = useState(false);
  const [heading, setHeading] = useState('');
  const [backPath, setBackPath] = useState('');

  const outletContext = {
    disableArrow,
    setDisableArrow,
    heading,
    setHeading,
    backPath,
    setBackPath,
  }

  return (
    <>
      <Header
        disableArrow={outletContext.disableArrow}
        heading={outletContext.heading}
        backPath={outletContext.backPath}
      />
      <section>
        <div className="p-4 mx-auto max-w-2xl lg:py-16">
          <Outlet context={{ setDisableArrow, setHeading, setBackPath }} />
        </div>
      </section>
    </>
  );
}

export default ModalLayout;
