import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { getUser } from "../api/user";
import toast from "react-hot-toast";

const defaultState = {
  user: null,
  setAuthUser: () => {},
  logout: () => {},
  isLoggedIn: false,
  loading: true,
}

export const AuthContext = createContext(defaultState);

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation('common');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = useCallback(async (token) => {
    try {
      const response = await getUser({
        token,
      });

      setUser(response);
    } catch (err) {
      console.error(err);
      toast.error(t('common:unexpectedError'));

      logout();
    } finally {
      setLoading(false);
    }
  }, [t]);

  useEffect(() => {
    const token = Cookies.get('jwtToken');

    if (token) {
      try {
        const { exp } = jwtDecode(token);

        if (Date.now() < exp * 1000 - 1440000) {
          fetchUserData(token);
        } else {
          logout();
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        toast.error(t('common:unexpectedError'));

        logout();
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [fetchUserData, t]);

  const setAuthUser = (userData, token = null) => {
    setUser(userData);

    if (token) {
      Cookies.set('jwtToken', token);
    }
  }

  const logout = () => {
    setUser(null);

    Cookies.remove('jwtToken');
  }

  const refreshUserData = () => {
    const token = Cookies.get('jwtToken');

    if (token) {
      setLoading(true);
      fetchUserData(token);
    }
  }

  const isLoggedIn = !!user;
  const hasCompany = !!user?.company;

  return (
    <AuthContext.Provider value={{ user, setAuthUser, logout, isLoggedIn, hasCompany, loading, refreshUserData }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
}
