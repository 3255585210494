import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ruCommon from "./locales/ru/common.json";
import ruAuth from "./locales/ru/auth.json";
import ruMeet from "./locales/ru/meet.json";
import ruCurrencies from "./locales/ru/currencies.json";
import enCommon from "./locales/en/common.json";
import enAuth from "./locales/en/auth.json";
import enMeet from "./locales/en/meet.json";
import enCurrencies from "./locales/en/currencies.json";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ru: {
        common: ruCommon,
        auth: ruAuth,
        meet: ruMeet,
        currencies: ruCurrencies,
      },
      en: {
        common: enCommon,
        auth: enAuth,
        meet: enMeet,
        currencies: enCurrencies,
      },
    },
    fallbackLng: 'ru',
    ns: ['common', 'auth', 'meet', 'currencies'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
