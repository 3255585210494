import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import FormInput from "../forms/FormInput";
import OAuth from "./OAuth";
import { login } from "../../api/auth";
import { useAuth } from "../../contexts/AuthContext";
import { getLoginRedirectUrl } from "../../utils/auth";

const LoginForm = () => {
  const { t } = useTranslation(['auth', 'common']);
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState(false);
  const { setAuthUser } = useAuth();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const inputs = [
    {
      type: 'text',
      name: 'email',
      id: 'email',
      label: t('auth:emailLabel'),
      placeholder: 'youremail@business.com',
      required: true,
      value: formData.email,
    },
    {
      type: 'password',
      name: 'password',
      id: 'password',
      label: t('auth:passwordLabel'),
      placeholder: '••••••••••••••',
      required: true,
      value: formData.password,
    },
  ];

  const handleChange = e => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsProcessing(true);
    setErrors(false);

    try {
      const response = await login(formData);
      const { user, token } = response;

      if (!user || !token) {
        toast.error(t('common:unexpectedError'));
      }

      setIsProcessing(false);
      setAuthUser(user, token);
      navigate(getLoginRedirectUrl(user));
    } catch (error) {
      console.error(error);

      setIsProcessing(false);

      error.response?.data?.errors ? setErrors(error.response.data.errors) : toast.error(t('common:unexpectedError'));
    }
  }

  return (
    <form
      className="space-y-4 md:space-y-6"
      onSubmit={handleSubmit}
    >
      {inputs.map((input, key) => (
        <FormInput
          input={input}
          key={key}
          onChange={handleChange}
          error={errors?.[input.name]}
        />
      ))}
      <div className="flex items-center justify-between">
        <Link
          to="/forgot-password"
          className="text-sm font-medium text-cyan-700 hover:underline"
        >
          {t('auth:forgotPassword')}
        </Link>
      </div>
      <Button
        type="submit"
        className="w-full"
        isProcessing={isProcessing}
        disabled={isProcessing}
      >
        {t('auth:login')}
      </Button>
      <OAuth />
      <p className="text-sm font-medium text-gray-900 dark:text-white">
        {t('auth:dontHaveAccount')}
        &nbsp;
        <Link to="/sign-up" className="font-medium text-cyan-700 hover:underline">
          {t('auth:signUp')}
        </Link>
      </p>
    </form>
  );
}

export default LoginForm;
