import { defaultCountries } from 'react-international-phone';
import countries from "i18n-iso-countries";

export const getCountries = ({ lang }) => {
  countries.registerLocale(require(`i18n-iso-countries/langs/${lang}.json`));

  const localizedCountries = defaultCountries.map((country) => {
    const [name, iso2, ...rest] = country;

    const localizedName = countries.getName(iso2.toUpperCase(), lang, {
      select: 'alias',
    });

    return [
      localizedName || name,
      iso2,
      ...rest,
    ];
  });

  localizedCountries.sort((a, b) => a[0].localeCompare(b[0]));

  return localizedCountries;
}
