import { useOutletContext, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CompanyForm from "../../components/meet/CompanyForm";
import { useEffect } from "react";
import { useFormContext } from "../../contexts/FormContext";

const MeetCompany = () => {
  const { t } = useTranslation('meet');
  const { setDisableArrow, setHeading, setBackPath } = useOutletContext();
  const { formData } = useFormContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(formData).length === 0) {
      navigate('/meet');
    }
  }, [formData, navigate]); 

  useEffect(() => {
    setDisableArrow(false);
    setHeading(t('meet:companySignUp'));
    setBackPath('/meet');
  }, [setDisableArrow, setHeading, setBackPath, t]);

  return <CompanyForm />;
}

export default MeetCompany;
