import { Link, Outlet } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Logo from "../identity/Logo";

const AuthLayout = ({ children }) => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <main className="w-full rounded-lg border border-gray-100 dark:border-gray-800">
        <section>
          <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 h-screen lg:py-0">
            <Link
              to="/"
              className="mb-6 flex items-center text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <Logo />
            </Link>
            <div className="w-full rounded-lg bg-white shadow sm:max-w-md md:mt-0 xl:p-0 dark:border dark:border-gray-700 dark:bg-gray-800">
              <div className="flex flex-col rounded-lg border border-gray-200 bg-white shadow-none dark:border-gray-700 dark:bg-gray-800">
                <div className="flex h-full flex-col justify-center gap-4 p-6">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </GoogleOAuthProvider>
  );
}

export default AuthLayout;
