import axios from "axios";
import Cookies from "js-cookie";
import i18n from "../i18n";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosClient.interceptors.request.use(config => {
  const token = Cookies.get('jwtToken');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers['Accept-Language'] = i18n.language.split('-')[0] || 'ru';

  return config;
}, error => {
  return Promise.reject(error);
});

export default axiosClient;
