import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MeetForm from "../../components/meet/MeetForm";
import { useEffect } from "react";

const Meet = () => {
  const { t } = useTranslation('meet');
  const { setDisableArrow, setHeading } = useOutletContext();

  useEffect(() => {
    setDisableArrow(true);
    setHeading(t('meet:letsMeet'))
  }, [setDisableArrow, setHeading, t]);

  return <MeetForm />;
}

export default Meet;
