import { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Button } from "flowbite-react";
import { useFormContext } from "../../contexts/FormContext";
import { useAuth } from "../../contexts/AuthContext";
import CardForm from "../forms/CardForm";
import { getIndustries, validateSphere } from "../../api/industry";
import { getBranches } from "../../api/branch";
import { getPositions } from "../../api/position";
import { reduceArray } from "../../utils/helpers";
import { validateFormData } from "./formValidation";
import employeesCount from "../../utils/employeesCount.json";
import OverlayPreloader from "../preloaders/OverlayPreloader";

const Form = () => {
  const { t, i18n } = useTranslation(['meet', 'common']);
  const [errors, setErrors] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [businessTypes, setBusinessTypes] = useState({});
  const [sphereOptions, setSphereOptions] = useState([]);
  const { user } = useAuth();
  const { formData, setFormData } = useFormContext();
  const navigate = useNavigate();
  const [wasFormValidated, setWasFormValidated] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const [positions, setPositions] = useState([]);

  const [localData, setLocalData] = useState({
    name: user.name,
    branches: '',
    position: '',
    type: '',
    sphere: '',
    employees: '',
    selfEmployed: '',
  });

  const initialized = useRef(false);

  useEffect(() => {
    const fetchData = async (lang) => {
      try {
        setLoading(true);
        
        if (!industries.length || !branches.length || !positions.length) {
          const [fetchedIndustries, fetchedBranches, fetchedPositions] = await Promise.all([getIndustries(), getBranches(), getPositions()]);
  
          setIndustries(fetchedIndustries);
          setBranches(fetchedBranches);
          setPositions(fetchedPositions);
          processAndSetData(fetchedIndustries, fetchedBranches, fetchedPositions, lang);
        } else {
          processAndSetData(industries, branches, positions, lang);
        }
      } catch (err) {
        console.error(err);
        toast.error(t('common:unexpectedError'));
      } finally {
        setLoading(false);
      }
    }
  
    const processAndSetData = (industries, branches, positions, lang) => {
      const _businessTypes = industries.reduce((acc, industry) => {
        if (!industry.parent) {
          acc[industry._id] = industry.name[lang];
        }

        return acc;
      }, {});
      
      const _branchList = branches.reduce((acc, branch) => {
        acc[branch._id] = branch.name[lang];

        return acc;
      }, {});
  
      const _positionList = positions.reduce((acc, position) => {
        acc[position._id] = position.name[lang];

        return acc;
      }, {});
  
      setBusinessTypes(_businessTypes);
      setBranchList(_branchList);
      setPositionList(_positionList);

      if (!initialized.current) {
        setLocalData((prevState) => ({
          ...prevState,
          branches: prevState.branches || Object.keys(_branchList)[0] || '',
          position: prevState.position || Object.keys(_positionList)[0] || '',
        }));

        initialized.current = true;
      }
    }
  
    fetchData(i18n.language.split('-')[0]);
  }, [i18n.language, t, industries, branches, positions]);

  useEffect(() => {
    const revalidate = () => {
      if (wasFormValidated) {
        setIsProcessing(true);
  
        const errors = validateFormData({
          i18n,
          data: localData,
        });
  
        setErrors(errors);
        setIsProcessing(false);
      }
    }

    i18n.on('languageChanged', revalidate);
  }, [i18n, localData, wasFormValidated]);

  const refreshAndGetSphereOptions = useCallback(value => {
    const _sphereOptions = industries.reduce((acc, industry) => {
      if (industry.parent === value) {
        acc[industry._id] = industry.name[i18n.language.split('-')[0]];
      }
      return acc;
    }, {});

    setSphereOptions(_sphereOptions);

    return _sphereOptions;
  }, [i18n.language, industries]);

  useEffect(() => {
    const { name, branches, position, type, sphere, employees, selfEmployed } = formData;

    setLocalData((prevState) => ({
      ...prevState,
      name: name || prevState.name,
      branches: branches || prevState.branches,
      position: position || prevState.position,
      type: type || prevState.type,
      sphere: sphere || prevState.sphere,
      employees: employees || prevState.employees,
      selfEmployed: selfEmployed || prevState.selfEmployed,
    }));

    refreshAndGetSphereOptions(type);
  }, [formData, refreshAndGetSphereOptions]);

  const aboutInputs = [
    {
      type: 'text',
      name: 'name',
      id: 'name',
      label: t('meet:nameLabel'),
      required: true,
      placeholder: t('meet:namePlaceholder'),
      value: localData.name,
    },
    {
      type: 'select',
      name: 'branches',
      id: 'branches',
      label: t('meet:branchesLabel'),
      required: true,
      placeholder: t('common:chooseFromList'),
      options: branchList,
      value: localData.branches,
    },
    {
      type: 'select',
      name: 'position',
      id: 'position',
      label: t('meet:positionLabel'),
      required: true,
      placeholder: t('common:chooseFromList'),
      options: positionList,
      value: localData.position,
    }
  ];

  const businessInputs = [
    {
      type: 'tag',
      name: 'type',
      id: 'type',
      label: t('meet:businessTypeLabel'),
      required: true,
      options: businessTypes,
      value: localData.type,
    },
    {
      type: 'select',
      name: 'sphere',
      id: 'sphere',
      label: t('meet:sphereLabel'),
      required: true,
      hidden: !localData.type,
      options: sphereOptions,
      value: localData.sphere,
    },
    {
      type: 'tag',
      name: 'employees',
      id: 'employees',
      label: t('meet:employeesLabel'),
      required: true,
      options: reduceArray(employeesCount),
      value: localData.employees,
    },
    {
      type: 'tag',
      name: 'selfEmployed',
      id: 'selfEmployed',
      label: t('meet:selfEmployedLabel'),
      required: true,
      options: {
        0: t('common:yes'),
        1: t('common:no'),
      },
      value: localData.selfEmployed,
    }
  ];

  const handleChange = e => {
    const { name, value } = e.target;
    let _formData = localData;

    if (name === 'type') {
      const _sphereOptions = refreshAndGetSphereOptions(value);

      _formData = {
        ...localData,
        sphere: Object.keys(_sphereOptions)[0],
      }
    }

    setLocalData({
      ..._formData,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsProcessing(true);

    const errors = validateFormData({
      i18n,
      data: localData,
    });

    setWasFormValidated(true);

    if (Object.entries(errors).length) {
      setIsProcessing(false);
      setErrors(errors);
    } else {
      const { type, sphere } = localData;

      try {
        await validateSphere({
          type,
          sphere
        });

        setIsProcessing(false);
        setErrors({});
        setFormData(localData);
        navigate('/meet/company');
      } catch (error) {
        setIsProcessing(false);

        console.error(error);

        error.response?.data?.errors ? setErrors(error.response.data.errors) : toast.error(t('common:unexpectedError'));
      }
    }
  }

  const sections = [
    {
      heading: t('meet:tellAboutYourself'),
      inputs: aboutInputs,
    },
    {
      heading: t('meet:tellAboutYourBusiness'),
      inputs: businessInputs,
    }
  ]

  return (
    <form
      className="grid gap-4 md:gap-6 relative"
      onSubmit={handleSubmit}
    >
      <CardForm
        onChange={handleChange}
        sections={sections}
        errors={errors}
      />
      <Button
        type="submit"
        className="w-full sticky bottom-4"
        isProcessing={isProcessing}
        disabled={isProcessing}
      >
        {t('common:next')}
      </Button>
      {!!loading && <OverlayPreloader />}
    </form>
  );
}

export default Form;
