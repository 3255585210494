import axios from "./axiosClient";
import { getErrorMessage } from "../lib/errors";

export const register = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/register', data)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}

export const login = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/login', data)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}

export const signInOAuth = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/oauth', data)
      .then(response => resolve(response.data))
      .catch(err => reject(new Error(getErrorMessage(err))));
  });
}

export const forgotPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/forgot-password', data)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}

export const resetPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/reset-password', data)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}

export const validateResetToken = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/validate-reset-token', data)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}
