import { Label } from "flowbite-react";

const AddressReadOnly = ({ label, value }) => {
  return (
    <div>
      <Label
        value={label}
        className="block"
      />
      <div className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-300">{value}</div>
    </div>
  );
}

export default AddressReadOnly;
