import cc from "currency-codes";
import countries from "i18n-iso-countries";

export const getCurrencyByCountry = countryCode => {
  if (countryCode === 'ru') {
    return 'RUB';
  }

  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

  const countryName = countries.getName(countryCode.toUpperCase(), 'en', {
    select: 'alias',
  });

  const currency = cc.country(countryName);

  return currency.length ? currency[0].code : null;
}
