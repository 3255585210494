import axios from "./axiosClient";

export const getIndustries = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/industries')
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}

export const validateSphere = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/industries/sphere/validate', data)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}
