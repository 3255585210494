
import { Card } from "flowbite-react";
import Heading from "../forms/Heading";
import FormInput from "./FormInput";

const CardForm = ({ sections, onChange, errors, onBlur }) => {
  return (
    <>
      {sections.map((section, key) => (
        <Card key={key}>
          <Heading text={section.heading} />
          <div className="grid gap-4 md:gap-6">
            {section.inputs.map((input, key) => (
              <FormInput
                input={input}
                key={key}
                onChange={onChange}
                onBlur={onBlur}
                error={errors?.[input.name]}
              />
            ))}
          </div>
        </Card>
      ))}
    </>
  )
}

export default CardForm;