const Heading = ({ type = 'h1', size, children, className }) => {
  const Tag = type;

  switch (size) {
    case 'h1':
      return <Tag className={`${className} text-5xl font-extrabold dark:text-white`}>{children}</Tag>;

    case 'h2':
      return <Tag className={`${className} text-4xl font-bold dark:text-white`}>{children}</Tag>;

    case 'h3':
      return <Tag className={`${className} text-3xl font-bold dark:text-white`}>{children}</Tag>;

    case 'h5':
      return <Tag className={`${className} text-xl font-bold dark:text-white`}>{children}</Tag>;

    case 'h6':
      return <Tag className={`${className} text-lg font-bold dark:text-white`}>{children}</Tag>;

    default:
      return <Tag className={`${className} text-2xl font-bold dark:text-white`}>{children}</Tag>;
  }
}

export default Heading;
