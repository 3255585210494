import { IoLogOut } from "react-icons/io5";
import { Link } from "react-router-dom";

const LogoutButton = () => {
  return (
    <Link
      to="/logout"
      className="rounded-lg p-2.5 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-700"
    >
      <IoLogOut size="20" />
    </Link>
  )
}

export default LogoutButton;
