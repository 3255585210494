export const generateCodeVerifier = () => {
  const array = new Uint32Array(32);

  window.crypto.getRandomValues(array);

  return Array.from(array, (dec) => ('0' + dec.toString(16)).slice(-2)).join('');
}

export const generateCodeChallenge = async (codeVerifier) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await crypto.subtle.digest('SHA-256', data);

  return btoa(String.fromCharCode(...new Uint8Array(digest))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
