import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GeoapifyContext, GeoapifyGeocoderAutocomplete } from "@geoapify/react-geocoder-autocomplete";
import toast from "react-hot-toast";
import { FaPencilAlt } from "react-icons/fa";
import { YMapComponentsProvider, YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapDefaultMarker } from "ymap3-components";
import { Button, useThemeMode } from "flowbite-react";
import AddressReadOnly from "./AddressReadOnly";
import AddressEditable from "./AddressEditable";
import AddressModal from "./AddressModal";
import { useFormContext } from "../../contexts/FormContext";
import { getCurrencyByCountry } from "../../utils/currencies";
import styles from "./AddressInput.module.css";
import OverlayPreloader from "../preloaders/OverlayPreloader";
import clsx from "clsx";

const AddressInput = ({ value, onChange, color }) => {
  const { i18n, t } = useTranslation('meet');
  const [showModal, setShowModal] = useState(false);
  const [focus, setFocus] = useState(null);
  const { formData } = useFormContext();
  const [addressChosen, setAddressChosen] = useState(false);
  const theme = useThemeMode();
  const [mapLoading, setMapLoading] = useState(true);

  const addressProperties = {
    addressLine1: t('meet:streetHomeProperty'),
    flat: t('meet:flatProperty'),
    city: t('meet:cityProperty'),
    countryName: t('meet:countryProperty'),
  }

  const handleChange = (value) => {
    try {
      const { street, housenumber, city, lat, lon, country, country_code, timezone, formatted, place_id } = value.properties;
      const currency = getCurrencyByCountry(country_code);

      const targets = [
        {
          name: 'lat',
          value: lat,
        },
        {
          name: 'lon',
          value: lon,
        },
        {
          name: 'countryName',
          value: country,
        },
        {
          name: 'formattedAddress',
          value: formatted,
        },
        {
          name: 'addressLine1',
          value: street || housenumber ? `${street || ''} ${housenumber || ''}`.trim() : null,
        },
        {
          name: 'city',
          value: city,
        },
        {
          name: 'country',
          value: country_code,
        },
        {
          name: 'timezone',
          value: timezone.name,
        },
        {
          name: 'language',
          // TODO: improve when more languages are available
          value: country_code === 'ru' ? 'ru' : 'en', 
        },
        {
          name: 'placeId',
          value: place_id,
        }
      ];

      if (currency) {
        targets.push({
          name: 'currency',
          value: currency,
        });
      }

      onChange({
        targets,
      });

      setAddressChosen(true);
    } catch (err) {
      console.error(err);
      toast.error(t('common:addressError'));
    }
  }

  const handleAdd = ({ e, name }) => {
    e.preventDefault();

    setShowModal(true);
    setFocus(name);
  }

  const handleMapError = err => {
    setMapLoading(false);

    console.error(err);
    toast.error(err);
  }

  const getAddressDetailInput = key => {
    if (formData?.[key]) {
      return (
        <div
          className="flex gap-2 justify-between items-end"
          key={key}
        >
          <AddressReadOnly
            label={addressProperties[key]}
            value={formData[key]}
          />
          {!!(key === 'countryName') && (
            <Button onClick={() => setShowModal(true)}>
              <FaPencilAlt />
            </Button>
          )}
        </div>
      );
    } else {
      return (
        <AddressEditable
          label={addressProperties[key]}
          key={key}
          name={key}
          onAdd={handleAdd}
        />
      );
    }
  }

  return (
    <>
      <div className={clsx(styles.geoapifyInput, styles[color])}>
        <GeoapifyContext apiKey={process.env.REACT_APP_GEOAPIFY_API_KEY}>
          <GeoapifyGeocoderAutocomplete
            key={i18n.language.split('-')[0]}
            type="amenity"
            placeholder={t('meet:addressPlaceholder')}
            value={value}
            lang={i18n.language.split('-')[0]}
            placeSelect={handleChange}
          />
        </GeoapifyContext>
        {!!addressChosen && (
          <>
            <div className="grid grid-cols-2 gap-6 mt-6">
              {Object.keys(addressProperties).map(key => getAddressDetailInput(key))}
            </div>
            <div className="mt-6 h-80 relative">
              <YMapComponentsProvider
                apiKey={process.env.REACT_APP_YANDEX_JAVASCRIPT_API_KEY}
                lang={!i18n.language.split('-')[0] || i18n.language.split('-')[0] === 'ru' ? 'ru_RU' : 'en_US'}
                onLoad={() => setMapLoading(false)}
                onError={handleMapError}
              >
                <YMap
                  location={{
                    center: [formData.lon, formData.lat],
                    zoom: 16
                  }}
                >
                  <YMapDefaultSchemeLayer
                    // eslint-disable-next-line tailwindcss/no-custom-classname
                    theme={theme.computedMode || 'light'}
                  />
                  <YMapDefaultFeaturesLayer />
                  <YMapDefaultMarker coordinates={[formData.lon, formData.lat]} size="small" />
                </YMap>
              </YMapComponentsProvider>
              {!!mapLoading && <OverlayPreloader />}
            </div>
          </>
        )}
      </div>
      <AddressModal
        show={showModal}
        onClose={() => setShowModal(false)}
        properties={addressProperties}
        focus={focus}
      />
    </>
  );
}

export default AddressInput;
