import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Label } from "flowbite-react";
import FormInput from "../forms/FormInput";
import OAuth from "./OAuth";
import { register } from "../../api/auth";
import { useAuth } from "../../contexts/AuthContext";

const SignUpForm = () => {
  const { t } = useTranslation(['auth', 'common']);
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState(false);
  const { setAuthUser } = useAuth();
  
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    phone: '',
  });

  const inputs = [
    {
      type: 'email',
      name: 'email',
      id: 'email',
      label: t('auth:emailLabel'),
      placeholder: 'youremail@business.com',
      required: true,
      value: formData.email,
    },
    {
      type: 'password',
      name: 'password',
      id: 'password',
      label: t('auth:passwordLabel'),
      placeholder: '••••••••••••••',
      required: true,
      value: formData.password,
    },
    {
      type: 'text',
      name: 'name',
      id: 'name',
      label: t('auth:nameLabel'),
      placeholder: t('auth:namePlaceholder'),
      required: true,
      value: formData.name,
    },
    {
      type: 'tel',
      name: 'phone',
      id: 'phone',
      label: t('auth:phoneLabel'),
      placeholder: t('auth:phonePlaceholder'),
      required: true,
      value: formData.phone,
    },
  ];

  const handleChange = e => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsProcessing(true);

    try {
      const response = await register(formData);
      const { user, token } = response;

      if (!user || !token) {
        toast.error(t('common:unexpectedError'));
      }

      setIsProcessing(false);
      setAuthUser(user, token);
      navigate('/meet');
    } catch (error) {
      setIsProcessing(false);

      console.error(error);

      error.response?.data?.errors ? setErrors(error.response.data.errors) : toast.error(t('common:unexpectedError'));
    }
  }

  return (
    <form
      className="space-y-4 md:space-y-6"
      onSubmit={handleSubmit}
    >
      {inputs.map((input, key) => (
        <FormInput
          input={input}
          key={key}
          onChange={handleChange}
          error={errors?.[input.name]}
        />
      ))}
      <div className="flex items-start">
        <div className="flex h-5 items-center">
          <Checkbox
            aria-describedby="terms"
            id="terms"
            required
          />
        </div>
        <div className="ml-3 text-sm">
          <Label
            htmlFor="terms"
            className="text-gray-500 dark:text-gray-300"
          >
            {t('auth:iAgreeWith')}
            &nbsp;
            <a
              href={`${process.env.REACT_APP_LANDING_URL}/terms`}
              className="text-cyan-700 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              {t('auth:termsLabel')}
            </a>
            &nbsp;
            и
            &nbsp;
            <a
              href={`${process.env.REACT_APP_LANDING_URL}/privacy`}
              className="text-cyan-700 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              {t('auth:privacyLabel')}
            </a>.
          </Label>
        </div>
      </div>
      <Button
        type="submit"
        className="w-full"
        isProcessing={isProcessing}
        disabled={isProcessing}
      >
        {t('auth:signUpButton')}
      </Button>
      <OAuth />
      <p
        className="text-sm font-medium text-gray-900 dark:text-white"
      >
        {t('auth:alreadyHaveAccount')}
        &nbsp;
        {t('auth:youCan')}
        &nbsp;
        <Link
          to="/login"
          className="font-medium text-cyan-700 hover:underline"
        >
          {t('auth:loginShort')}
        </Link>
        &nbsp;
        {t('auth:intoAccount')}
      </p>
    </form>
  );
}

export default SignUpForm;
