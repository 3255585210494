// import branches from "../../utils/branches.json";
// import positions from "../../utils/positions.json";
import employeesCount from "../../utils/employeesCount.json";

export const validateFormData = ({ i18n, data }) => {
  const errors = {};
  // const branchValues = branches.map(branch => branch.value);
  // const positionValues = positions.map(position => position.value);

  if (!data.name) {
    errors.name = {
      message: i18n.t('meet:nameRequired'),
    }
  }

  if (!data.branches) {
    errors.branches = {
      message: i18n.t('meet:branchesChoose'),
    }
  // } else if (!branchValues.includes(data.branches)) {
  //   errors.branches = {
  //     message: i18n.t('common:wrongSelectValue'),
  //   }
  }

  if (!data.position) {
    errors.position = {
      message: i18n.t('meet:positionSelect'),
    }
  // } else if (!positionValues.includes(data.position)) {
  //   errors.position = {
  //     message: i18n.t('common:wrongSelectValue'),
  //   }
  }

  if (!data.type) {
    errors.type = {
      message: i18n.t('meet:businessTypeChoose'),
    }
  } else if (!data.sphere) {
    errors.sphere = {
      message: i18n.t('meet:sphereChoose'),
    }
  }

  if (!data.employees) {
    errors.employees = {
      message: i18n.t('meet:employeesChoose'),
    }
  } else if (!employeesCount.includes(data.employees)) {
    errors.employees = {
      message: i18n.t('common:wrongSelectValue'),
    }
  }

  if (!data.selfEmployed) {
    errors.selfEmployed = {
      message: i18n.t('meet:selfEmployedChoose'),
    }
  } else if (!['0', '1'].includes(data.selfEmployed)) {
    errors.selfEmployed = {
      message: i18n.t('common:wrongSelectValue'),
    }
  }

  return errors;
}