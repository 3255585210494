import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "flowbite-react";
import { FlagImage } from "react-international-phone";
import languages from "../../utils/languages.json";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(Object.entries(languages).find(([key, value]) => value.i18n === i18n.language.split('-')[0])?.[0] || 'ru');

  const handleLanguageChange = language => {
    i18n.changeLanguage(language);

    setSelectedLanguage(language);
  }

  return (
    <div className="flex flex-wrap gap-4">
      <Dropdown
        label={
          <div className="flex gap-2 items-center">
            <FlagImage iso2={languages[selectedLanguage].iso2} />
            <span className="hidden md:block">{languages[selectedLanguage].name}</span>
          </div>
        }
        placement="bottom"
        theme={{
          arrowIcon: 'ml-2 mt-1 size-4',
        }}
        color="gray"
      >
        {Object.keys(languages).map((language, index) => {
          return (
            <Dropdown.Item
              onClick={() => handleLanguageChange(language)}
              key={index}
              className="flex gap-2"
            >
              <FlagImage iso2={languages[language].iso2} />
              <span>{languages[language].name}</span>
            </Dropdown.Item>
          );
        })}
      </Dropdown>
    </div>
  )
}

export default LanguageSelector;
