import Icon from "./Icon";

const OverlayPreloader = () => {
  return (
    <div className="absolute inset-0 m-0 flex items-center justify-center bg-gray-50/50 dark:bg-gray-500/50 rounded-sm">
      <Icon />
    </div>
  );
}

export default OverlayPreloader;
